// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { isMobile } from "react-device-detect"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import tvBgImg from "./assets/tv-bg.jpg"
import tvPersonImg from "./assets/tv-person.png"
import tvKidImg from "./assets/tv-kid.png"
import tvImg from "./assets/tv-frame.png"
import tailImg from "./assets/tail.png"

// Sprites
import droneImg from "./assets/sprites/drone.png"
import speakerDotsImg from "./assets/sprites/speaker-dots.png"
import plantsImg from "./assets/sprites/plants.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 7,
  preHeading: "Out of office",
  heading: "This must be the place where the office is a choice.",
  content:
    "It’s where we come to collaborate. We can walk in for a workshop on a Wednesday or pop in to plan a project over a pot of tea. Here, we schedule meetings around me-time and you-time. It’s where we come together to talk together, as well as work together. We ditch the duty of the daily commute and do our jobs remotely, if it suits. Here, office time is meaningful time.",
  slug: "out-of-office",
  nextSlug: "our-home",
}

const OutOfOffice = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -80, y: 0 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: 136, y: 612 },
    }),
    tvBg = useRef({
      parallax: 0.5,
      src: tvBgImg,
      el: null,
      offset: { x: 1445, y: 445 },
    }),
    tvPerson = useRef({
      parallax: 0.5,
      src: tvPersonImg,
      el: null,
      offset: { x: 1633, y: 534 },
    }),
    tvKid = useRef({
      parallax: 0.5,
      src: tvKidImg,
      el: null,
      offset: { x: 1780, y: 720 },
    }),
    tv = useRef({
      parallax: 0.5,
      src: tvImg,
      el: null,
      offset: { x: 1465, y: 462 },
    }),
    drone = useRef({
      parallax: 5,
      src: droneImg,
      el: null,
      offset: { x: 3600, y: -200, r: 1 },
      size: { w: 166, h: 200 },
      rotationPoint: { x: 83, y: 5 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    speakerDots = useRef({
      parallax: 0.5,
      src: speakerDotsImg,
      el: null,
      offset: { x: 822, y: 1145 },
      size: { w: 40, h: 10 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    plants = useRef({
      parallax: 0.5,
      src: plantsImg,
      el: null,
      offset: { x: 482, y: 942 },
      size: { w: 300, h: 680 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 4,
      paused: true,
    }),
    tail = useRef({
      parallax: 1,
      src: tailImg,
      el: null,
      offset: { x: 570, y: 1617, r: 0 },
      rotationPoint: { x: 168, y: 11 },
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    mg.current,
    tvBg.current,
    tvKid.current,
    tvPerson.current,
    tv.current,
    drone.current,
    speakerDots.current,
    plants.current,
    fg.current,
    tail.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([drone.current, plants.current, speakerDots.current])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 960,
    y: 1288,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  // Initial animations
  const startingAnimations = () => {
    gsap.fromTo(
      drone.current.offset,
      { r: 5 },
      { r: -5, duration: 2, ease: "power2.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      tail.current.offset,
      { r: 12 },
      { r: -12, duration: 0.6, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.to(tvKid.current.offset, {
      x: 2117,
      duration: 0.7,
      ease: "back.out(1.4)",
      delay: 5,
    })
    gsap.to(drone.current.offset, {
      y: 288,
      duration: 10,
      ease: "back.out(1.3)",
      delay: 6,
    })
    gsap.to(drone.current.offset, {
      x: 1975,
      duration: 20,
      ease: "back.out(1.3)",
      delay: 6,
    })
  }

  // Timed animations
  const showPlant = () => {
    plants.current.paused = false
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()
      if (isMobile) showPlant("in")
    }, 1000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showPlant,
              x: 500,
              y: 900,
              w: 410,
              h: 810,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default OutOfOffice
